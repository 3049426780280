
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAxTpZELDatdG2yeuoqRw7jKvgtCvzLtRo",
    authDomain: "finance-x-f89b4.firebaseapp.com",
    projectId: "finance-x-f89b4",
    storageBucket: "finance-x-f89b4.appspot.com",
    messagingSenderId: "604234513197",
    appId: "1:604234513197:web:b970b52181dd6d9c9e31a4",
    measurementId: "G-W60W1TDY01"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
