import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import BlogScreen from './screens/BlogScreen';
import BlogPage from './pages/BlogPage';
import './App.css';
import CreditScreen from './screens/CreditScreen';
import ConsortiumScreen from './screens/ConsortiumScreen';
import AntecipationScreen from './screens/AntecipationScreen';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/linhas-de-credito" element={<CreditScreen/>} />
          <Route path="/consorcio" element={<ConsortiumScreen/>} />
          <Route path="/contato" element={<AboutScreen/>} />
          <Route path="/sobre-nós" element={<ContactScreen/>} />
          <Route path="/antecipacao-de-credito" element={<AntecipationScreen/>} />
          <Route path="/blog" element={<BlogScreen />} />
          <Route path="/blog/:postId" element={<BlogPage />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
