import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../components/Navbar.css';

function Navbar() {
  return (
    <div className='navbar'>
      <div className='navbar_Content'>
        <Link to="/" className='navbar_Logo'>
            <img src="images/logo.png" alt="logo"/>
          </Link>
        <div className='navbar_Items'>
          <Link to="/">Home</Link>
          {/* <Link to="/servicos">Serviços</Link> */}
          <Link to="/blog">Blog</Link>
          <Link to="/contato">Contato</Link>
          <Link to="/sobre-nós">Sobre nós</Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
